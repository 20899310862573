import { ReactComponent as HourOneSvg } from "app/assets/realsme/hourone-large.svg";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import BackButton from "app/pages/PlayGround/BackButton";
import useCredits from "app/hooks/useCredits";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import usePermissions from "app/hooks/usePermissions";
import React, { Key } from "react";
import { useUpgradeByPlan } from "app/hooks/useUpgradeModal";
import { apiDocsUrl } from "app/utils/urls";
import OppositeUpgradeButton from "app/components/common/UpgradeButton/OppositeUpgradeButton";
import styled, { keyframes } from "styled-components";
import {
  Button,
  Checkbox,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  Selection,
  SelectItem,
  Tooltip
} from "@nextui-org/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { FeatureFlag } from "app/types";
import { useAppSelector } from "app/hooks";

const tilt = keyframes`
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(1deg);
    }
    50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(-1deg);
    }
    100% {
        transform: rotate(0deg);
    }
`;

const ContentFlexRow = styled(H1_FlexRow)`
  padding: 0 10px;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: #3c4257;
  }
`;
const StyledPopover = styled(Popover)`
  div[data-slot="content"] {
    background-color: #27272a;
  }
  top: 20px;
  right: 20px;
  position: absolute;
`;
const StyledSelect = styled(Select)`
  width: 270px;
  flex: 1 0 auto;
  &&& button {
    transition: background-color 0.3s ease-in-out;
    background-color: #27272A;
    &:hover {
      background-color: #3F3F46};
    }
    label {
      color: #D4D4D8;
    }
    span[data-slot="value"] {
      color: #71717A;
    }
    svg path {
      fill: white;
    }
  }
  &&& button {
    animation: ${tilt} 0.5s ease-in-out 0s 2; /* Animation lasts 0.5 seconds, repeats twice */
    transition: transform 0.3s ease-in-out;
    background: linear-gradient(90deg, #262626ee 0%, #26262699 100%);
    border: 1px solid #262626;

    &:hover {
      background-color: ${({ theme }) => theme.gray4};
      transform: scale(1.03);
    }

    label {
      font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
      font-size: 16px;
      color: #d4d4d8;
    }
    span[data-slot="value"] {
      font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
      font-size: 14px;
      color: #fafafa;
    }
  }
`;

interface PlaygroundTopBarProps {
  onBack?: () => void;
  onChangeLively: (lively: boolean) => void;
  onChangeSuperRes: (superRes: boolean) => void;
  onModelSelection: (model: Selection) => void;
  lively: boolean;
  superRes: boolean;
  model: string | undefined;
}
const PlaygroundTopBar = ({
  onBack,
  onChangeLively,
  onChangeSuperRes,
  onModelSelection,
  lively,
  superRes,
  model
}: PlaygroundTopBarProps) => {
  const { available, isUnlimited } = useCredits();
  const { shouldShowUpgradeButton } = usePermissions();
  const upgradeModal = useUpgradeByPlan();
  const flags = useFlags();
  const modelsList = useAppSelector((state) => state.playground.modelsList);

  const modelsItems = modelsList.map((model) => ({
    key: model.name,
    label: model.name
  }));

  const onUpgrade = () => {
    upgradeModal({ source: `upgrade_playground_header` });
  };

  const onClickApiDocs = () => {
    window.open(apiDocsUrl, "_blank");
  };

  return (
    <H1_FlexRow justify="space-between" align="center" padding="13px">
      <H1_FlexRow align="center" gap="12px">
        <H1_FlexRow align="center">
          <ConditionalRender condition={!!onBack}>
            <BackButton onBack={onBack} />
          </ConditionalRender>
          <HourOneSvg height={33} width={30} />
        </H1_FlexRow>
        <ConditionalRender condition={flags[FeatureFlag.playgroundModels]}>
          <H1_FlexRow gap="20px" align="center" flex="1" width="100%">
            <StyledSelect
              defaultSelectedKeys={
                flags[FeatureFlag.playgroundModels] ? ["bison-hope2"] : undefined
              }
              selectorIcon={<H1_Icon color="white" icon="far fa-chevron-down" />}
              size="sm"
              onSelectionChange={onModelSelection}
              items={modelsItems}
              labelPlacement="outside"
              selectedKeys={[model as Key]}
              classNames={{
                listbox: "text-gray-8",
                popoverContent: "bg-gray-1/85 backdrop-blur-[4px]",
                value: "truncate",
                selectorIcon: "right-3",
                innerWrapper: "w-full"
              }}
            >
              {modelsItems.map((item) => (
                <SelectItem key={item.key}>
                  <Tooltip classNames={{ content: ["text-white"] }} content={item.label}>
                    <span>{item.label}</span>
                  </Tooltip>
                </SelectItem>
              ))}
            </StyledSelect>
          </H1_FlexRow>
        </ConditionalRender>
      </H1_FlexRow>
      <H1_FlexRow position="relative" align="center" gap="16px">
        <ConditionalRender condition={!isUnlimited}>
          <H1_TextSmall color="#8C8C8C">{available}s Left</H1_TextSmall>
        </ConditionalRender>
        <ConditionalRender condition={shouldShowUpgradeButton}>
          <OppositeUpgradeButton source="playground" onClick={onUpgrade} />
        </ConditionalRender>
        <H1_FlexRow className="playground-popover-container">
          <StyledPopover
            placement="bottom-end"
            color="default"
            portalContainer={document.querySelector(".playground-popover-container") as Element}
            align={{ offset: [20, 20] }}
          >
            <PopoverTrigger>
              <H1_Icon isCursorPointer color="white" icon="fas fa-gear" />
            </PopoverTrigger>
            <PopoverContent>
              <ContentFlexRow
                height="30px"
                width="170px"
                align="center"
                justify="space-between"
                onClick={() => onChangeLively(!lively)}
              >
                <H1_TextSmall color="white">Lively</H1_TextSmall>
                <Checkbox isSelected={lively} onClick={() => onChangeLively(!lively)} />
              </ContentFlexRow>
              <ContentFlexRow
                height="30px"
                width="170px"
                align="center"
                justify="space-between"
                onClick={() => onChangeSuperRes(!superRes)}
              >
                <H1_TextSmall color="white">Super Resolution</H1_TextSmall>
                <Checkbox isSelected={superRes} onClick={() => onChangeSuperRes(!superRes)} />
              </ContentFlexRow>
            </PopoverContent>
          </StyledPopover>
        </H1_FlexRow>
        <Button color="primary" variant="faded" size="sm" onClick={onClickApiDocs}>
          API Docs
        </Button>
      </H1_FlexRow>
    </H1_FlexRow>
  );
};
export default PlaygroundTopBar;
